<template>
  <div>
  <b-row>
    <b-col v-show="false" md="5" class="p-2">
      <b-button @click="youtubeLogin()" class="google-btn">
        <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google-ico.png')}) transparent 5px 50% no-repeat;`"></span>
        <span class="google-text">{{ $t('login.googleLogin') }}</span>
      </b-button>
    </b-col>
  </b-row>
    
  <b-tooltip target="connection-info">{{$t('infoConnection')}}</b-tooltip>
  <b-card
    body-class="border-card-info"
    header-class="border-header">
    <template #header>
      <div class="d-flex justify-content-between mb-1 mb-sm-0">
        <span class="title-text">{{ $t('socialConnections')  }} <b-icon icon="question-circle" id="connection-info"></b-icon></span> 
      </div>
      <div v-if="networks.length > 0 && loaded">
        <b-button @click="show_sider_network = true; is_adding_network = true" class="blue-button" variant="blue-button">
          <b-icon icon="plus-circle"/>
          {{$t('buttonAddNetwork')}}
        </b-button> 
      </div>
    </template>
    <div v-if="networks.length === 0 && loaded" class="mt-3 d-flex justify-content-center">
      <b-button @click="show_sider_network = true; is_adding_network = true" class="blue-button-empty-nets" variant="blue-button-empty-nets">
        <b-icon icon="plus-circle"/>
        {{$t('buttonAddNetwork')}}
      </b-button> 
    </div>

    <div class="container-social-networks-list-settings" v-if="loaded">
      <div v-for="(network, index) in networks" :key="index" class="network-item-list-manage mt-2" :style="`border-left: 15px solid ${getColorV2(network.network, false)}`">
        <div :style="`background: ${getColorV2(network.network, false)}`" class="background-color-net d-none d-sm-flex">
          <b-img :src="getIconWhite(network.network)" fluid class="icon-profile-connection"/>
        </div>
        <div class="p-1 d-flex justify-content-between align-items-center w-100">
          <div class="w-for-info-p">
            <small>{{ nameNetworks(network.network) }} <b-badge v-if="!isNetworkUnavaibleToConnect(network.network)" pill :class="network.connected ? 'success-pill-badge' : 'danger-pill-badge'">{{ network.connected ? $t("creator.verified"): $t("creator.notVerified") }}</b-badge></small>
            <small class="m-0 avenir-medium text-overflow d-flex">{{(network.name ? network.name : network.username)}}</small>
            <small v-if="network.followers === 0"><b-badge class="danger-pill-badge mt-03">{{network.followers}} {{$t('campaigns.followers')}}</b-badge></small>
          </div>
          <div class="d-flex">
            <div class="button-icon-actions-nets">
              <b-dropdown variant="link" button-class="bg-danger" class="dropdown-action-networks-settings avenir-medium" no-caret right>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" class="icon-actions-nets"></feather-icon>
                </template>
                <b-dropdown-item @click="clickEdit(network)">
                  <feather-icon icon="EditIcon"></feather-icon>
                  {{ $t('edit') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="network.connected && checkNetworkConnect(network.network)" @click="checkToken(network)">
                  <feather-icon icon="CheckIcon"></feather-icon>
                  {{ $t('profile.checkPermisssions') }}
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="deleteNetwork(network)">
                  <feather-icon icon="TrashIcon" class="mr-05"></feather-icon>
                  {{ $t('disconnected') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-social-networks-list-settings" v-else>

      <div v-for="(_, index) in 3" :key="index" class="network-item-list-manage mt-2">
        <div class="background-color-net d-none d-sm-flex overflow-hidden">
          <b-skeleton class="w-100 h-100 m-0"></b-skeleton>
        </div>
        <div class="p-1 d-flex justify-content-between align-items-center w-100">
          <div class="w-for-info-p">
            <small class="d-flex"> <b-skeleton class="col-2 mb-05"></b-skeleton> <b-skeleton class="col-1 ml-1 mb-05"></b-skeleton></small>
            <small class="d-flex"> <b-skeleton class="col-4 mb-05"></b-skeleton></small>
            <small class="d-flex" v-if="index === 0"> <b-skeleton class="col-2 m-0"></b-skeleton></small>
          </div>
        </div>
      </div>

    </div>
  </b-card>
    <sider-network 
     class="open-sidebar-search"
     @close_siderbar="closeSiderNetwork"
     @updated_network="getNetworks"
     @updated_network_edit="updateNetworkEdit"
      :show_sider_network="show_sider_network"
      :is_adding="is_adding_network"
      v-if="show_sider_network"
      ref="sider_network"
      />
    <modal-videos-youtube v-if="videos_youtube.length > 0" :videos="videos_youtube" :modalShow="modal_videos" :key="change_modal_videos"/>
    <modal-media-instagram v-if="instagram_medias.length > 0" :medias="instagram_medias" :username="username_modal" :modalShow="modal_instagram" :key="change_modal_instagram"/>
    <consent-spotify :modalShow="modal_consent" :key="change_modal_consent" @loginSpotify="redirectSpotifyLogin"/>
    <modal-brandme :show_modal="show_modal_username" :key="change_modal_username">
      <template #title>
        <span>{{$t('titleConfirmUsername')}}</span>
      </template>
      <template #body>
        <p>{{$t('messageConfirmUsername')}} {{username_validate}}</p>
        <div class="d-flex justify-content-between">
          <b-button variant="outline-danger" @click="cancelConfirmUsername()">{{$t('campaigns.msjCancel')}}</b-button>
          <b-button variant="success" @click="confirmUsername()">{{$t('campaigns.confirmButton')}}</b-button>
        </div>
      </template>
    </modal-brandme>

    <modal-brandme :show_modal="show_modal_login" :key="`modal_login_${change_modal_login}`">
      <template #body>
        <div class="login-facebook">
        <v-facebook-login

          :app-id="facebook_app_id"
          v-model="user_face"
          :login-options="facebookOptions"
          :options="optionsButton"
          @login="getUserDataFacebook"
          @sdk-init="handleSdkInit"
          :key="network_to_link"
          v-if="network_to_link === 'facebook'"
        >
        </v-facebook-login>

        <v-facebook-login
          :app-id="facebook_business_app_id"
          v-model="insta_face"
          :login-options="instagramOptions"
          :options="optionsButton"
          @login="getUserDataInstagram"
          @sdk-init="handleSdkInitInstagram"
          :key="network_to_link"
          v-if="network_to_link === 'instagram'"
        >
        </v-facebook-login>
      </div>

        <b-button @click="youtubeLogin()" class="google-btn" v-if="network_to_link === 'youtube'">
          <span class="google-icon" :style="`background: url(${require('@/assets/images/google/google-ico.png')}) transparent 5px 50% no-repeat;`"></span>
          <span class="google-text">{{ $t('login.googleLogin') }}</span>
        </b-button>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BBadge,
  BIcon,
  BTooltip,
  BButton,
  BRow,
  BCol,
  BCard,
  BImg,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSkeleton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import service from "@/services/others";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import social_service from '@/services/social'
import VFacebookLogin from 'vue-facebook-login-component'
import ModalVideosYoutube from "./ModalVideosYoutube.vue";
import ModalMediaInstagram from "./ModalMediaInstagram.vue";
import ConsentSpotify from './ConsentSpotify.vue'
import ModalBrandme from "@/views/components/modal/ModalBrandme.vue";
import siderNetwork from "@/views/pages/proposals/stepper/siderNetwork.vue";
import { getIconWhite, getColorV2 } from '@/libs/utils/icons'
import { nameNetworks, isNetworkUnavaibleToConnect } from '@/libs/utils/formats';
import { getMotivationalPhrase } from '@/libs/utils/others'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BIcon,
    BTooltip,
    siderNetwork,
    BButton,
    BRow,
    BCol,
    BSkeleton,
    BCard,
    VFacebookLogin,
    ModalVideosYoutube,
    ModalMediaInstagram,
    ConsentSpotify,
    ModalBrandme,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      getIconWhite,
      getColorV2,
      nameNetworks,
      isNetworkUnavaibleToConnect,
      show_sider_update: false,
      networkUpdate: {
        username: '',
        followers: '',
      },
      show_sider_network: false,
      networks: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      netIconsWhite: {
        tiktok: require("@/assets/images/icons/tiktok.svg"),
        spotify: require("@/assets/images/icons/spotify.svg"),
        pinterest: require("@/assets/images/icons/pinterest.svg"),
        blog: require("@/assets/images/icons/blogger.svg"),
        snapchat: require("@/assets/images/icons/snapchat.svg"),
      },
      netIconsColor: {
        tiktok: require("@/assets/images/icons/tiktok2.svg"),
        spotify: require("@/assets/images/icons/spotify2.svg"),
        pinterest: require("@/assets/images/icons/pinterest2.svg"),
        blog: require("@/assets/images/icons/wordpress-icon.svg"),
        snapchat: require("@/assets/images/icons/snapchat2.svg"),
      },
      netOptions: [
        { value: "instagram", text: "Instagram" },
        { value: "youtube", text: "YouTube" },
        { value: "tiktok", text: "TikTok" },
        { value: "twitter", text: "Twitter" },
        { value: "facebook", text: "Facebook" },
        { value: "linkedin", text: "Linkedin" },
        { value: "spotify", text: "Spotify" },
        { value: "pinterest", text: "Pinterest" },
        { value: "twitch", text: "Twitch" },
        { value: "blog", text: "Blog" },
        { value: "snapchat", text: "Snapchat" },
      ],
      selected: null,
      
      facebookOptions: {
        scope: 'email,user_link',
        return_scopes: true,
        auth_type: 'rerequest'
      },
      optionsButton: {
        cookie: true,
        xfbml: true,
        version: 'v17.0',
      },
      fb: {},
      scope_facebook: {},
      ig: {},
      scope_instagram: {},
      user_face: {},
      facebook_app_id: process.env.VUE_APP_FACEBOOK_APP_ID,
      videos_youtube: [],
      modal_videos: false,
      change_modal_videos: false,
      facebook_business_app_id: process.env.VUE_APP_FACEBOOK_BUSINESS_APP_ID,
      insta_face: {},
      instagramOptions: {
        scope: 'instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,business_management',
        return_scopes: true,
        auth_type: 'rerequest'
      },
      instagram_medias: [],
      modal_instagram: false,
      change_modal_instagram: false,
      username_modal: '',
      modal_consent: false,
      change_modal_consent: false,
      username_validate: '',
      show_modal_username: false,
      change_modal_username: 0,
      network_to_link: '',
      show_modal_login: false,
      change_modal_login: 0,
      loaded: false,
      interval_loading: null,
      is_adding_network: true,
    };
  },
  created() {
    this.getNetworks();
    
    this.redeemToken()
    this.redeemTokenTiktok()
    this.redeemTokenYoutbe()
    this.redeemTokenLinkedin()
    this.redeemTokenTwitch()
    this.redeemTokenPinterest()
    this.redeemTokenSpotify()
    this.redeemTokenSnapchatt()
  },
  methods: {
    updateMetrics (user_network_uuid) {
      this.showLoader()
      service.updateMetricsUserNetwork(user_network_uuid).then(() => {
        this.hideLoader()
      })
    },
    getClassNetworkColorSelected(network) {
      if (network === 'facebook') return 'icomoon-Facebook-2 facebook-border new-border'
      if (network === 'instagram') return 'icomoon-instagram1 instagram-border new-border'
      if (network === 'linkedin') return 'icomoon-Linkedin linkedin-border new-border'
      if (network === 'twitch') return 'iconNetwork-twitch twitch-border new-border'
      if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
      if (network === 'youtube') return 'icomoon-youtube youtube-border new-border'
      if (network === 'tiktok') return 'icomoon-tiktok tiktok-border new-border'
      if (network === 'spotify') return 'iconNetwork-spotify spotify-border new-border'
      if (network === 'pinterest') return 'icomoon-pinterest2 pinterest-border new-border'
      if (network === 'blog') return require("@/assets/images/icons/wordpress-icon.svg")
      if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat-border new-border'
    },
    clickEdit(profile) {
      this.show_sider_network = true;
      this.is_adding_network = false;
      this.networkUpdate = { ...profile };
      setTimeout(() => {
        this.$refs.sider_network.editNetworkAction(this.networkUpdate);
      }, 20);
    },
    updateNetworkEdit(network_updated) {
      this.networks = this.networks.map((i) => {
        if (i.uuid === network_updated.uuid) return {...network_updated}
        return i
      });
    },
    closeSiderNetwork() {
      this.show_sider_network = false
    },
    checkNetworkConnect(network) {
      return network === 'twitter' || network === 'facebook' || network === 'youtube'  || network === 'tiktok' || network === 'linkedin' || network === 'twitch' || network === 'instagram' || network === 'pinterest' || network === 'spotify'
    },
    redirectPinterestLogin() {
      localStorage.setItem('network_code', 'pinterest')
      const client_id = process.env.VUE_APP_PINTEREST_ID
      const redirect_uri = process.env.VUE_APP_TWITCH_CALLBACK
      const scope = 'ads:read,boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read,catalogs:read,catalogs:write'
      const state = Math.random().toString(36).substring(2);
      const url = `https://www.pinterest.com.mx/oauth/?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=${state}`
      window.location.href = url;
    },
    redirectSnapchatLogin() {
      localStorage.setItem('network_code', 'snapchat')
      const state = Math.random().toString(36).substring(2);
      const redirectUri = process.env.VUE_APP_TWITCH_CALLBACK;
      const clientId = process.env.VUE_APP_SNAPCHAT_ID;
      const scopeList = [
        'https://auth.snapchat.com/oauth2/api/user.display_name',
        'https://auth.snapchat.com/oauth2/api/user.bitmoji.avatar',
        'https://auth.snapchat.com/oauth2/api/user.external_id'
      ];
      const scope = scopeList.join(' ');

      const loginQS = new URLSearchParams({
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: 'code',
        scope,
        state,
      });

      const SNAP_ACCOUNTS_LOGIN_URL = 'https://accounts.snapchat.com/accounts/oauth2/auth';
      window.location.href = `${SNAP_ACCOUNTS_LOGIN_URL}?${loginQS}`
    },
    showLoader() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    hideLoader() {
      clearInterval(this.interval_loading)
      this.$vs.loading.close()
    },
    redeemTokenSnapchatt() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'snapchat') {
        this.showLoader()
        const form_data = new FormData()
        form_data.append('code', code)
        social_service.redeem_code('snapchat', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.hideLoader()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/social')
            }, 50)
          })
      } else if (error && network_code === 'snapchat') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    redirectTwitchLogin() {
      localStorage.setItem('network_code', 'twitch')
      const state = Math.random().toString(36).substring(2);
      const client_id = process.env.VUE_APP_TWITCH_ID
      const redirect_uri = process.env.VUE_APP_TWITCH_CALLBACK
      const scope = 'analytics:read:games+bits:read+channel:manage:schedule+channel:manage:videos+channel:read:editors+channel:read:goals+channel:read:hype_train+channel:read:polls+channel:read:predictions+channel:read:redemptions+channel:read:subscriptions+moderation:read+user:read:email+user:read:follows+user:read:subscriptions'
      const url = `https://id.twitch.tv/oauth2/authorize?force_verify=true&response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`
      window.location.href = url;
    },
    redirectLinkedinLogin() {
      localStorage.setItem('network_code', 'linkedin')
      const state = Math.random().toString(36).substring(2);
      const client_id = process.env.VUE_APP_LINKEDIN_ID
      const redirect_uri = process.env.VUE_APP_LINKEDIN_CALLBACK

      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=r_liteprofile+r_emailaddress+w_member_social`
      window.location.href = url
    },
    redirectTiktokLogin() {
      localStorage.setItem('network_code', 'tiktok')
      const state = Math.random().toString(36).substring(2);
      const client_key = process.env.VUE_APP_CLIENT_KEY_TIKTOK
      const redirect_uri = process.env.VUE_APP_TIKTOK_CALLBACK
      const url = `https://www.tiktok.com/v2/auth/authorize?client_key=${client_key}&scope=user.info.basic,video.list,user.info.profile,user.info.stats&response_type=code&state=${state}&redirect_uri=${redirect_uri}`
      window.location.href = url;
    },
    redirectSpotifyLogin() {
      localStorage.setItem('network_code', 'spotify')
      const state = Math.random().toString(36).substring(2);
      const client_key = process.env.VUE_APP_SPOTIFY_ID
      const redirect_uri = process.env.VUE_APP_LINKEDIN_CALLBACK
      const scope = 'user-read-private user-read-email' // user-follow-read user-read-currently-playing user-top-read user-library-read
      const url = `https://accounts.spotify.com/authorize?response_type=code&client_id=${client_key}&scope=${scope}&redirect_uri=${redirect_uri}&state=${state}`
      window.location.href = url;
    },
    showToast(title, text, variant, icon = 'AlertCircleIcon', position = 'top-right') {
      loadToastificationContent().then((component) => {
        this.$toast({
          component: component.default,
          position,
          props: {
            title,
            icon,
            variant,
            text,
          }
        })
      })
    },
    redeemTokenSpotify() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'spotify') {
        this.showLoader()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))
        social_service.redeem_code('spotify', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.hideLoader()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/social')
            }, 50)
          })
      } else if (error && network_code === 'pinterest') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    redeemTokenPinterest() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'pinterest') {
        this.showLoader()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))
        social_service.redeem_code('pinterest', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.hideLoader()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/social')
            }, 50)
          })
      } else if (error && network_code === 'pinterest') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    redeemTokenTwitch() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'twitch') {
        this.showLoader()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))
        social_service.redeem_code('twitch', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            this.hideLoader()
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/social')
            }, 50)
          })
      } else if (error && network_code === 'twitch') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    redeemTokenLinkedin() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'linkedin') {
        this.showLoader()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))

        social_service.redeem_code('linkedin', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('completeInfo'),
                  this.$t('editInfo'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }

            localStorage.removeItem('network_code');
            this.$router.push(response.new ? { name: 'my_work', params: { section: 'networks' } } : '/pages/account-setting/social').catch(() => {});
            this.hideLoader()
          })
      } else if (error && network_code === 'linkedin') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )
        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    redeemTokenTiktok () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'tiktok') {
        this.showLoader()
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))

        social_service.redeem_code('tiktok', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialError'),
                'danger'
              )
            } else if (response.code && response.code === 'errorYoutube') {
              this.showToast(
                this.$t('socialErrorTitle'),
                this.$t('socialErrorYoutube'),
                'danger'
              )
            } else {
              this.getNetworks();
              if (response.new) {
                this.showToast(
                  this.$t('socialConnected'),
                  this.$t('socialSuccess'),
                  'success'
                )
              } else {
                this.showToast(
                  this.$t('socialAlreadyConnected'),
                  this.$t('socialAlreadySuccess'),
                  'warning'
                )
              }
            }
            
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/social')
            }, 50)
            this.hideLoader()
          })
      } else if (error && network_code === 'tiktok') {
        this.showToast(
          this.$t('socialErrorTitle'),
          this.$t('socialErrorYoutube'),
          'danger'
        )
        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    redeemTokenYoutbe () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get('code')
      const error = urlParams.get('error')

      const network_code = localStorage.getItem('network_code')

      if (code && network_code === 'youtube') {
        const form_data = new FormData()
        form_data.append('code', code)
        form_data.append('username', localStorage.getItem('user_to_link'))

        social_service.redeem_code('youtube', form_data)
          .then(response => {
            if (response.code && response.code === 'accountNetwrokExists') {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialErrorTitle'),
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: this.$t('socialError'),
                  }
                })
              })
            } else if (response.code && response.code === 'errorYoutube') {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialErrorTitle'),
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: this.$t('socialErrorYoutube'),
                  }
                })
              })
            } else {
              this.getNetworks();
              if (response.new) {
                loadToastificationContent().then((component) => {
                  this.$toast({
                    component: component.default,
                    position: 'top-right',
                    props: {
                      title: this.$t('socialConnected'),
                      icon: 'AlertCircleIcon',
                      variant: 'success',
                      text: this.$t('socialSuccess'),
                    }
                  })
                })
              } else {
                loadToastificationContent().then((component) => {
                  this.$toast({
                    component: component.default,
                    position: 'top-right',
                    props: {
                      title: this.$t('socialAlreadyConnected'),
                      icon: 'AlertCircleIcon',
                      variant: 'warning',
                      text: this.$t('socialAlreadySuccess'),
                    }
                  })
                })
              }
            }
            
            localStorage.removeItem('network_code');
            const self = this
            setTimeout(function () {
              self.$router.push('/pages/account-setting/social')
            }, 50)
          })
      } else if (error && network_code === 'youtube') {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('deniedOauthTitle'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: this.$t('deniedOauth'),
            }
          })
        })

        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    youtubeLogin() {
      localStorage.setItem('network_code', 'youtube')
      const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

      const options = {
        client_id: process.env.VUE_APP_GOOGLE_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_CALLBACK,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: 'https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly',
        hl: 'en'
      };

      const qs = new URLSearchParams(options);

      window.location.href = `${rootUrl}?${qs.toString()}`
    },
    checkToken(profile) {
      this.showLoader()
      social_service.validate_token(profile.uuid)
        .then(response => {
          this.hideLoader()
          if (!response.valid_token) {
            if (profile.network === 'facebook') {
              this.showLoader()
              this.makeLogin()
            } else if (profile.network === 'twitter') {
              this.redirectTwitter()
            } else if (profile.network === 'tiktok') {
              this.redirectTiktokLogin();
            } else if (profile.network === 'youtube') {
              this.youtubeLogin()
            } else if (profile.network === 'linkedin') {
              this.redirectLinkedinLogin()
            } else if (profile.network === 'instagram') {
              this.makeLoginInstagram();
            } else if (profile.network === 'twitch') {
              this.redirectTwitchLogin();
            } else if (profile.network === 'pinterest') {
              this.redirectPinterestLogin()
            } else if (profile.network === 'snapchat') {
              this.redirectSnapchatLogin()
            } else if (this.selected === 'spotify') {
              this.modal_consent = true
              this.change_modal_consent = !this.change_modal_consent
            }
          } else {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('profile.permissionsActiveTitle'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: this.$t('profile.permissionsUpdated'),
                }
              })
            })
          }
        })
    },
    handleSdkInit({ FB, scope }) {
      this.fb = FB
      this.scope_facebook = scope
    },
    handleSdkInitInstagram({ FB, scope}) {
      this.ig = FB
      this.scope_instagram = scope
    },
    oauth() {
      if (this.selected === 'twitter') {
        this.redirectTwitter()
      } else if (this.selected === 'facebook') {
        this.showLoader()
        this.makeLogin()
      } else if (this.selected === 'twitch') {
        this.redirectTwitchLogin();
      } else if (this.selected === 'linkedin') {
        this.redirectLinkedinLogin();
      } else if (this.selected === 'tiktok') {
        this.redirectTiktokLogin();
      } else if (this.selected === 'pinterest') {
        this.redirectPinterestLogin();
      } else if (this.selected === 'snapchat') {
        this.redirectSnapchatLogin();
      } else if (this.selected === 'spotify') {
        this.modal_consent = true
        this.change_modal_consent = !this.change_modal_consent
      }
    },
    makeLogin () {
      this.show_modal_login = true
      this.network_to_link = 'facebook'
      this.change_modal_login += 1
    },
    makeLoginInstagram () {
      this.show_modal_login = true
      this.network_to_link = 'instagram'
      this.change_modal_login += 1
      // const self = this
      // this.ig.login(function(response) {
      //   if (response.authResponse) {
      //     self.getUserDataInstagram(response)
      //   } else {
      //     self.$vs.loading.close()
      //     self.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: self.$t('deniedOauthTitle'),
      //         icon: 'AlertIcon',
      //         variant: 'danger',
      //         text: self.$t('deniedOauth'),
      //       },
      //     });
      //   }
      // }, self.instagramOptions);
    },
    getUserDataInstagram(data) {
      if (!data || !data.authResponse) return
      this.showLoader()
      const form_data = new FormData()
      form_data.append('access_token', data.authResponse.accessToken)
      form_data.append('signed_request', data.authResponse.signedRequest)
      form_data.append('user_id', data.authResponse.userID)
      form_data.append('username', localStorage.getItem('user_to_link'))
      this.showLoader()
      social_service.save_token_instagram(form_data)
        .then(() => {
          this.show_modal_login = false
          this.network_to_link = ''
          this.change_modal_login += 1
          const self = this
          setTimeout(function () {
            self.getNetworks();
            self.$vs.loading.close()
          }, 1000)
        })
    },
    getUserDataFacebook(data) {
      if (!data || !data.authResponse) return
      this.showLoader()
      const form_data = new FormData()
      form_data.append('access_token', data.authResponse.accessToken)
      form_data.append('signed_request', data.authResponse.signedRequest)
      form_data.append('user_id', data.authResponse.userID)
      form_data.append('username', localStorage.getItem('user_to_link'))

      social_service.save_token_facebook(form_data)
        .then(response => {
          this.show_modal_login = false
          this.network_to_link = ''
          this.change_modal_login += 1
          this.hideLoader()
          this.scope_facebook.logout()
          if (response.code && response.code === 'accountNetwrokExists') {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('socialErrorTitle'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: this.$t('socialError'),
                }
              })
            })
          } else if (response.code) {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('socialErrorTitle'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: this.$t('tryAgain'),
                }
              })
            })
          } else {
            this.getNetworks();
            if (response.new) {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialConnected'),
                    icon: 'AlertCircleIcon',
                    variant: 'success',
                    text: this.$t('socialSuccess'),
                  }
                })
              })
            } else {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialAlreadyConnected'),
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                    text: this.$t('socialAlreadySuccess'),
                  }
                })
              })
            }
          }
        })
      this.hideLoader()
    },
    redirectTwitter() {
      this.showLoader()
      social_service.get_url_login_twitter()
        .then(response => {
          const url = response.url
          localStorage.setItem('token_twitter', response.temp_secret);
          window.location.href = url;
        })
    },
    redeemToken() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const oauth_key = urlParams.get('oauth_token')
      const oauth_token = localStorage.getItem('token_twitter') ? localStorage.getItem('token_twitter') : 'xxx'
      const oauth_verifier = urlParams.get('oauth_verifier')
      const denied = urlParams.get('denied')
      const username = localStorage.getItem('user_to_link')

      if (oauth_key && oauth_verifier) {
        this.showLoader()
        social_service.redeem_token_twitter(oauth_key, oauth_token, oauth_verifier, username)
          .then(response => {
            localStorage.removeItem('token_twitter');
            this.hideLoader()

            if (response.code && response.code === 'accountNetwrokExists') {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialErrorTitle'),
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: this.$t('socialError'),
                  }
                })
              })
            } else if (response.code) {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('socialErrorTitle'),
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: this.$t('tryAgain'),
                  }
                })
              })
            } else {
              if (response.new) {
                loadToastificationContent().then((component) => {
                  this.$toast({
                    component: component.default,
                    position: 'top-right',
                    props: {
                      title: this.$t('socialConnected'),
                      icon: 'AlertCircleIcon',
                      variant: 'success',
                      text: this.$t('socialSuccess'),
                    }
                  })
                })
              } else {
                loadToastificationContent().then((component) => {
                  this.$toast({
                    component: component.default,
                    position: 'top-right',
                    props: {
                      title: this.$t('socialAlreadyConnected'),
                      icon: 'AlertCircleIcon',
                      variant: 'warning',
                      text: this.$t('socialAlreadySuccess'),
                    }
                  })
                })
              }
              this.getNetworks();
              localStorage.removeItem('network_code');
              const self = this
              setTimeout(function () {
                self.$router.push('/pages/account-setting/social')
              }, 50)
            }
          })
      } else if (denied) {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('deniedOauthTitle'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: this.$t('deniedOauth'),
            }
          })
        })
        localStorage.removeItem('network_code');
        const self = this
        setTimeout(function () {
          self.$router.push('/pages/account-setting/social')
        }, 50)
      }
    },
    getNetworks() {
      service.getSocialNetworks().then((response) => {
        this.networks = response.results;
        this.loaded = true;
      })
        .catch(() => {
        });
    },
    cancelConfirmUsername() {
      this.show_modal_username = false
      this.change_modal_username += 1
    },
    confirmUsername() {
      this.newNetwork.username = this.username_validate
    },
    deleteNetwork(network) {
      const self = this
      self.$swal({
        title: this.$t('confirmDeleteNetworkTitle'),
        text: this.$t('confirmDeleteNetwork'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn confirm-button ',
          cancelButton: 'btn btn-outline-danger mr-1'
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t('msjConfirmNetwork'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        reverseButtons: true,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          service.removeSocialNetwork(network.uuid).then(() => {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t("socialDeleted"),
                  icon: "Trash2Icon",
                  variant: "danger",
                  text: this.$t("socialDeleteddata"),
                }
              })
            })
            this.getNetworks();
          });
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
.mt-03 {
  margin-top: 3px;
}
.mb-05 {
  margin-bottom: 0.5em
}
.w-for-info-p {
  width: 87%;
}
.text-overflow {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon-profile-connection {
  width: 30px !important;
  height: 30px !important;
  margin: 0em 2em 
}
.container-social-networks-list-settings {
  height: 800px;
  overflow-y: scroll;
  padding-bottom: 1em;
  @media(max-width: 575px) {
    height: auto;
  }
}
.network-item-list-manage {
  border: 1px solid #ccc;
  margin-bottom: 1em;
  height: 86px;
  border-radius: 1em;
  display: flex;

  @media(max-width: 575px) {
    height: auto;
  }
  @media(min-width: 576px) {
    border: 1px solid #ccc !important;
  }
  .success-pill-badge {
    color: rgb(6, 150, 6) !important;
    background-color: rgba(0, 128, 0, 0.122) !important;
    text-transform: capitalize;
    font-size: 11px;
  }
  .danger-pill-badge {
    color: rgb(116, 17, 17) !important;
    background-color: rgba(255, 0, 0, 0.102) !important;
    font-size: 11px;
  }
  .background-color-net {
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-right: 1px solid #cccccc60;
    border-bottom-left-radius: 0.9em;
    border-top-left-radius: 0.9em;
  }
  .icon-network-profile-connection {
    font-size: 24px;
    color: white;
  }
  .icon-network-profile-connection-13 {
    font-size: 15px;
    color: white;
  }
}
</style>
<style lang="scss">

.dropdown-action-networks-settings {

  .btn {
    padding: 0.6em !important;
    &:hover {
      background-color: #e7e7e7;
    }
  }
}
.btn-disabled {
  cursor: text !important;
  margin-top: 8px;
  margin-right: 6px;
  text-decoration: none !important;
  color: #b9b9c3 !important;
}
.icon-alert-profile-settings {
  position: absolute;
  z-index: 2;
  top: 22px;
  left: -4px;
  font-size: 13px;
}
.variant-avatar {
  background-color: #ffffff !important;
}
.text-followers {
  padding: 0.4rem;
  margin-left: 10px;
  margin-right: 10px;
}
.btn-connect {
  background: #0096fe !important;
  border: none;
  color: white !important;
  margin-right: 24px !important;
  width: 130px;
}
.btn-connect:focus {
  background: #0096fe !important;
  border: none;
  color: white !important;
  margin-right: 38px;
}
.link-edit {
  cursor: pointer;
  margin-top: 8px;
  margin-right: 6px;
  text-decoration: none !important;
  color: #495057 !important;
}
.btn-disconnected {
  --bs-text-opacity: 1;
  color: #b41212 !important;
}
.btn-disconnected:hover {
  background: rgba(222, 226, 230, 0.178);
}
.network-disconnected {
  height: 20px;
  margin-left: 9px;
  margin-right: 10px;
  color: #6c0b0b;
  background: #f0d0d0;
}
.text-network {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 13px;
  margin-right: 3px;
}
.container-network {
  margin-left: 65px; 
  height: 38px !important;
}
.icon-network {
  font-size: 1.4rem;
  color: white !important;
}
.icon-network-twitter-setting {
  width: 65%;
  height: 65%;
}
.icon-content-twitter-setting {
  display: flex;
  background: black;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.position-avatar-general {
  position: absolute;
}
.title-text {
  font-weight: bolder !important;
}
.border-header {
  border: 0.0625rem solid rgba(222,226,230,.7) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem !important;
}
.border-card-info {
  border: 0.0625rem solid rgba(222,226,230,.7);
  border-top: none;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
}
.blue-button-empty-nets {
  background: #0096fe !important;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 60px;
  padding-right: 60px;
  border: none;
  font-size: 16px;
  color: white !important;
}
.v-facebook-login{
  background:#3578E5 !important;
}
.login-facebook{
  display: flex;
  justify-content: center;
}
.size-text-msj{
  margin-top:10px;
  font-size: small;
}
.net-social-message{
  margin-top: 14px !important;
  padding: 20px;
  text-align: center;
  display: flex;
}
.social-message-disconnected{
  margin-top: 14px !important;
  padding: 20px;
  text-align: justify;
  display: block;
  font-size: medium;
}
.max-height-networks-settings {
height: 800px;
}
.button-dropdown > button {
  padding: 7px;
}
.open-sidebar-search > .b-sidebar-right {
animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
  @media(max-width:767px){
    .submenu{
      margin-top:514px ;
    }
  }  
</style>